<template>
  <div class="page">
    <van-nav-bar title="我的" />
    <div class="content">
      <div class="avatar">
        <i class="iconfont icon-w_mine"></i>
      </div>
      <div class="info">
        <div>
          {{ userInfo.realName }}({{
            userInfo.username.split(",")[1] === "ENTERPRISE" ? "企业" : "个人"
          }})
        </div>
        <div>
          {{ userInfo.username.split(",")[0] | sensitiveField(3, 4) }}
        </div>
      </div>
    </div>
    <div class="operate">
      <div class="line"></div>
      <div class="operate-item" @click="toSalesmanList()">
        <div class="left">
          <i class="iconfont icon-icons-"></i>
          <span class="title">管理业务员</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="line"></div>
    </div>

    <div class="logout">
      <van-button color="#FF463C" type="danger" block @click="logout"
        >退出登录</van-button
      >
    </div>

    <TabBarNav></TabBarNav>
  </div>
</template>
<script>
import { getUserInfo, clearUserInfo } from "@/libs/utils";
import { Dialog } from "vant";
import { Tabbar, TabbarItem } from "vant";
import Vue from "vue";
Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
  data() {
    return {
      userInfo: getUserInfo(),
    };
  },
  methods: {
    async logout() {
      if (
        (await Dialog.confirm({
          title: "提示",
          message: "确认退出登录",
        })) !== "confirm"
      ) {
        return;
      }
      clearUserInfo();
      this.$router.push({
        name: "Login",
      });
    },

    //跳转业务员列表
    toSalesmanList() {
      this.$router.push({
        name: "salesmanList",
      });
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.page {
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .logout {
    padding: 32px 54px 32px 54px;
    .van-button {
      font-size: 15px;
      color: #ffffff;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vw;
    padding-top: 40px;
    background: transparent url(~@/assets/images/mine-cover@2x.png) no-repeat
      center center;
    background-size: cover;
    .avatar {
      width: 88px;
      height: 88px;
      box-sizing: border-box;
      border-radius: 50%;
      background: #eaebef;
      border: 8px solid white;
      display: flex;
      justify-content: center;
      align-items: center;

      .iconfont {
        color: #787b84;
        font-size: 50px;
      }
    }
    .info {
      text-align: center;
      margin-top: 18px;

      div {
        &:nth-child(1) {
          font-size: 18px;
          color: #2a2b2e;
          letter-spacing: 0;
        }
        &:nth-child(2) {
          margin-top: 16px;
          font-size: 12px;
          color: #787b84;
          letter-spacing: 0;
        }
      }
    }
  }
  .operate {
    margin-top: -40px;
    .line {
      height: 1px;
      background: #e5e7ed;
    }
    .operate-item {
      background: #ffffff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 21px 21px 21px 25px;
      // padding: 0 20px;
      // padding-top: 40px;

      .left {
        display: flex;
        align-items: center;
        i {
          font-size: 20px;
          margin-right: 5px;
          color: #3c86ff;
        }

        .title {
          font-size: 14px;
          color: #34363c;
          font-weight: 500;
        }
      }

      .van-icon {
        color: #d3d6dc;
        font-size: 20px;
      }
    }
  }
}
</style>
